<template>
    <div class="pageWrapper details">

        <div class="identity"></div>

        <div class="pageLeft">
            <div class="inner">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back_white.svg" />
                    </router-link>
                </div> 
                
                <div class="pageTitle">
                    <h1>{{pagetitle}}</h1>
                </div>
                <p>Garden Cactus Record (GCR) is a music label based in Germany, dedicated to  promote innovative talents in the dance, deep house, chill, and pop genres.</p>

                <p>In this project, I was tasked to design an artist management system with the main purposes of digitalize and centralize the artist royalty earning through multiple streaming platform. With this system, the product owner can skip  manually payout calculation and able to track back the records anytime they want.</p>
                <p>Contributions :
                    <ul>
                        <li>User flow planning</li>
                        <li>System interface design for both artist and admin portal</li>
                    </ul>
                </p>

            </div>
        </div>
        <div class="pageRight">
            <div class="inner">
                
                <div class="showcase">
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/gcr/cover.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/gcr/showcase/1.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/gcr/showcase/2.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/gcr/showcase/3.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/gcr/showcase/4.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/gcr/showcase/5.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/gcr/showcase/6.png">
                    </div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    import PhotoSwipe from 'photoswipe/dist/photoswipe'
    import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
    import 'photoswipe/dist/photoswipe.css'
    import 'photoswipe/dist/default-skin/default-skin.css'
    import createPreviewDirective from "vue-photoswipe-directive";
    
    export default {
        directives: {
            preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI)
        },
        name: 'gcr',
        data () {
            return {
                pagetitle: 'Garden Cactus Record',
            }
        }
    }
</script>