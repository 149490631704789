<template>
    <div class="pageWrapper">

        <div class="identity"></div>
        
        <div class="pageLeft">  
            <div class="inner">
                <h1>Hello.</h1>
                
                <p class="text-large text-white">My name is KinHang an introverted designer from Malaysia.</p>
                <p>My journey in design began with graphic design, evolved into web design and current role as a UI/UX designer. Along the way, I've learned front-end styling which ensuring designs are not only visually compelling but also seamlessly implemented.</p>
                <p>I have journeyed through the evolution of digital design starting with crafting traditional websites, transitioning to intuitive mobile app experiences and now embracing the cutting-edge world of AI-driven design.</p>
            </div>

            <div v-if="!isTabletPortrait" class="contact">
                <p>Get in touch for colaboration or just to say hi! 👋</p>

                <a href="https://www.linkedin.com/in/kinhang-aw-455185181" target="_blank">
                    <img src="@/assets/images/icon_linkedin.svg">
                </a>
            </div>
        </div>

        <div class="pageRight">
            <div class="inner">
                
                <label class="showcase-label">Past Project</label>
                <div class="showcase">
                    <div class="item" v-for="project in projects" :key="project.id">
                        <router-link :to="project.url">
                            <template v-if="project.id == 1"><img src="@/assets/images/works/tourplus/cover.png"></template>
                            <template v-if="project.id == 2"><img src="@/assets/images/works/amopay/cover.png"></template>
                            <template v-if="project.id == 3"><img src="@/assets/images/works/gcr/cover.png"></template>
                            <template v-if="project.id == 4"><img src="@/assets/images/works/glk/cover.png"></template>
                            <div class="description">
                                <p>{{project.name}}</p>
                                <p>{{project.desc}}</p>
                            </div>
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
        
        <div v-if="isTabletPortrait" class="contact">
            <p>Get in touch for colaboration or just to say hi! 👋</p>

            <a href="https://www.linkedin.com/in/kinhang-aw-455185181" target="_blank">
                <img src="@/assets/images/icon_linkedin.svg">
            </a>
        </div>

    </div>
</template>

<script> 
    export default {
        name: 'home',
        data () {
            return {
                projects:[
                    {
                        id: '1',
                        name: 'Tourplus',
                        desc: 'Product Design, Branding',
                        url: '/works/tourplus'
                    },
                    {
                        id: '2',
                        name: 'AmoPay',
                        desc: 'UI Design',
                        url: '/works/amopay'
                    },
                    {
                        id: '3',
                        name: 'GCR',
                        desc: 'UI UX Design',
                        url: '/works/gcr'
                    },
                    {
                        id: '4',
                        name: 'GLK Birdnest',
                        desc: 'UI Design, Front-end Styling',
                        url: '/works/glk'
                    },
                    /*{
                        id: '11',
                        name: 'Traworld Merchant',
                        desc: 'UI & UX design',
                        url: '/works/traworld'
                    },
                    {
                        id: '9',
                        name: 'BlueMount',
                        desc: 'UI design, Front-end Developerment',
                        url: '/works/bluemount'
                    },
                    {
                        id: '10',
                        name: 'Trading App Case Study',
                        desc: 'UI & UX Design',
                        url: '/works/trading'
                    },
                    {
                        id: '8',
                        name: 'BBB Superapp',
                        desc: 'UI & UX Design',
                        url: '/works/bbbsuperapp'
                    },
                    {
                        id: '0',
                        name: 'Bitpays',
                        desc: 'Logo Design, UI & UX Design',
                        url: '/works/bitpays'
                    },
                    {
                        id: '1',
                        name: 'TrexChain',
                        desc: 'UI design & development',
                        url: '/works/trexchain'
                    },
                    {
                        id: '9',
                        name: 'RedCliff',
                        desc: 'UI Design & Development',
                        url: '/works/redcliff'
                    },
                    {
                        id: '3',
                        name: 'CloudTac',
                        desc: 'Logo design, UI & UX Design',
                        url: '/works/cloudtac'
                    },
                    {
                        id: '2',
                        name: 'W Entertechment',
                        desc: 'Web design, Coin Design',
                        url: '/works/wentertechment'
                    },
                    {
                        id: '4',
                        name: 'MiFun Network',
                        desc: 'Branding & web design',
                        url: '/works/mifun'
                    },
                    {
                        id: '6',
                        name: 'Stuttgart',
                        desc: 'Social media post design',
                        url: '/works/stuttgart'
                    },*/
                ],

                isTabletPortrait: false,
            }
        },
        methods: {
            updateTabletPortraitStatus() {
            this.isTabletPortrait =
                window.matchMedia('(max-width: 1024px) and (orientation: portrait)').matches;
            },
        },
        mounted() {
            this.updateTabletPortraitStatus();
            window.addEventListener('resize', this.updateTabletPortraitStatus);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateTabletPortraitStatus);
        },
    };
</script>

<style scoped>
</style>
