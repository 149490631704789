<template>
    <div class="pageWrapper details">

        <div class="identity"></div>

        <div class="pageLeft">
            <div class="inner">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back_white.svg" />
                    </router-link>
                </div> 
                
                <div class="pageTitle">
                    <h1>{{pagetitle}}</h1>
                </div>
                <p>AmoPay is a cryptocurrency based payment gateway industry which include various payment services in between cryptocurrency and fiat currency.</p>

                <p>In this project, I was tasked with taking over an existing design and reconstructing the design system to improve consistency. My responsibilities included studying the inherited design, identifying gaps, and ensuring all elements aligned with the corporate identity. This involved refining color palettes, typography, and visual hierarchy to match brand guidelines while maintaining a user-friendly experience.</p>
                <p>However, my contributions to user experience were limited, as the product owner already had a high-level flow in mind. My role was to provide suggestions and enhancements for the UI, incorporating user experience considerations where possible.</p>
                <p>Contributions :
                    <ul>
                        <li>Reorganize the project design system</li>
                        <li>To consistent the usage of components and colours</li>
                        <li>Enhance the merchant portal interface, payment services and many more</li>
                        <li>Minor contribution on animated graphic on css</li>
                    </ul>
                </p>

            </div>
        </div>
        <div class="pageRight">
            <div class="inner">
                
                <div class="showcase">
                    <div class="item">
                        <img v-preview:scope-b src="@/assets/images/works/amopay/cover.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-b src="@/assets/images/works/amopay/showcase/1.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-b src="@/assets/images/works/amopay/showcase/2.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-b src="@/assets/images/works/amopay/showcase/3.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-b src="@/assets/images/works/amopay/showcase/4.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-b src="@/assets/images/works/amopay/showcase/5.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-b src="@/assets/images/works/amopay/showcase/6.png">
                    </div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    import PhotoSwipe from 'photoswipe/dist/photoswipe'
    import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
    import 'photoswipe/dist/photoswipe.css'
    import 'photoswipe/dist/default-skin/default-skin.css'
    import createPreviewDirective from "vue-photoswipe-directive";
    
    export default {
        directives: {
            preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI)
        },
        name: 'tourplus',
        data () {
            return {
                pagetitle: 'AmoPay',
            }
        }
    }
</script>