<template>
    <div class="pageWrapper details">

        <div class="identity"></div>

        <div class="pageLeft">
            <div class="inner">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back_white.svg" />
                    </router-link>
                </div> 
                
                <div class="pageTitle">
                    <h1>{{pagetitle}}</h1>
                </div>
                <p>GLK is a birdnest distributor with various of product thorugh online or physically shop.</p>

                <p>In this project, I was tasked to design a mobile app for to diverse their business  while also increase the user stickiness through loyalty point. Also, customer are able to look for the nearest store and order through the mobile app with the preferred collection method.</p>
                <p>Contributions :
                    <ul>
                        <li>Mobile app and landing page design</li>
                        <li>Front-end template styling</li>
                    </ul>
                </p>

            </div>
        </div>
        <div class="pageRight">
            <div class="inner">
                
                <div class="showcase">
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/glk/cover.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/glk/showcase/1.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/glk/showcase/2.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/glk/showcase/3.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/glk/showcase/4.png">
                    </div>
                    
                    <div class="item">
                        <img v-preview:scope-a src="@/assets/images/works/glk/showcase/5.png">
                    </div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    import PhotoSwipe from 'photoswipe/dist/photoswipe'
    import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
    import 'photoswipe/dist/photoswipe.css'
    import 'photoswipe/dist/default-skin/default-skin.css'
    import createPreviewDirective from "vue-photoswipe-directive";
    
    export default {
        directives: {
            preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI)
        },
        name: 'glk',
        data () {
            return {
                pagetitle: 'GLK',
            }
        }
    }
</script>